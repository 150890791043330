import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { API_URL, IMAGE_URL } from "../../config/Config";
import Spinner from "../Spinner";
import { useNavigate, Link } from "react-router-dom";
import { get } from "../../config/apis";
import ModalImage from "react-modal-image";
import dateFormat from "dateformat";


function Rejectedkyc() {
  const navigate = useNavigate();
  const [currentPage, setcurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [perPage, setPerPage] = useState(10);
  const [user, setUser] = useState('');
  const [records, setRecords] = useState(0);
  const [search, setSearch] = useState('')
  const [value, setValue] = useState('')
  const [sorting, setSorting] = useState({ key: '', ascending: false })
  let [p, setP] = useState(' ')

  const handleFetch = async () => {
    setUser(null)
    setLoading(true);
    var passcon = '';
    if (value !== '' && value !== undefined) {
      passcon = `&search=${search}&value=${value?.toLocaleLowerCase()}`;
    }
    const res = await get(`${API_URL}/kyc/rejected?sort=${sorting.key}&kyc_status=Rejected&page=${currentPage}&limit=${perPage}${passcon}`,
      {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": 'Bearer ' + localStorage.getItem('Token')
      }
    )
    if (res?.status === 'success') {
      setUser(res);
      setRecords(res.total)
      setLoading(false);
      setP(res.page + 1)
    } else if (res?.status === 'error') {
      localStorage.clear()
      navigate("/login");
    }
  };
  const handlePageChange = async (e) => {
    const newOffset = (e.selected + 1);
    setcurrentPage(newOffset);
  };

  useEffect(() => {
    handleFetch(); // eslint-disable-next-line
  }, [currentPage, perPage, search, value]);

  const submitForm = async (e) => {
    e.preventDefault();
    handleFetch();
  }


  const applySorting = (key, ascending) => {
    if (ascending) {
      setSorting({ key: '', ascending: false });
      setSorting({ key: '-' + key, ascending: ascending });
      handleFetch();
    } else {
      setSorting({ key: '', ascending: false });
      setSorting({ key: key, ascending: ascending });
      handleFetch();

    }
  }


  const handleChange = async (e) => {
    e.preventDefault();
    setPerPage(e.target.value);
  }


  return (
    <div className="content-wrapper-new">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6"><h1>REJECTED KYC</h1></div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item active">Rejected KYC</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <div className="card card-primary card-outline card-outline-tabs mb-11">
        <div className="card-body p-0">
          <div className="tab-content" id="custom-tabs-four-tabContent">
            <div className="tab-pane fade active show" id="detail-tab">
              <div className="row">
                <div className="col-12">
                  <div className="">

                    <div className="row mb-2">
                      <div className="col-xl-10 col-lg-8 col-md-7 ">
                        <div className="d-flex justify-content-between">
                          <div className="form-group m-0 text-nowrap">
                            <span>Show</span>
                            <select className="form-control  text-center w-auto d-inline mx-2" onChange={handleChange}>
                              <option defaultValue={10}>10</option>
                              <option value={20}>20</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>
                            <span>entries</span>
                          </div>

                          <form action="#" method="get">
                            <select onChange={(e) => setSearch(e.target.value)} name="search" className="btn btn-active">
                              <option value={'username'}>USERNAME</option>
                              <option value={'first_name'}>FIRST NAME</option>
                              <option value={'country'}>COUNTRY</option>
                              <option value={'pan_number'}>PAN NUMBER</option>
                            </select>
                          </form>

                        </div>

                      </div>

                      <div className="col-xl-2 col-lg-4 col-md-5">
                        <form action="#" method="get" >

                          <div className="card-tools m-0">
                            <div className="input-group input-group-sm" style={{ width: '100%' }}>
                              <input type="text" name="value" className="form-control" placeholder="Search" onChange={(e) => setValue(e.target.value)} />
                              <div className="input-group-append">
                                <button type="submit" className="btn btn-default" onClick={submitForm}>
                                  <i className="fas fa-search" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>

                    </div>
                    <div className="card-body table-responsive p-0">

                      <table className="table  text-nowrap">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th onClick={() => applySorting('username', !sorting.ascending)} >USERNAME
                              <i className={sorting.key === '-username' || sorting.key === 'username' ? sorting.ascending === true && sorting.key === '-username' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                            </th>
                            <th onClick={() => applySorting('document_type', !sorting.ascending)} >DOCUMENT TYPE
                              <i className={sorting.key === '-document_type' || sorting.key === 'document_type' ? sorting.ascending === true && sorting.key === '-document_type' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                            </th>
                            <th onClick={() => applySorting('first_name', !sorting.ascending)} >FIRST NAME
                              <i className={sorting.key === '-first_name' || sorting.key === 'first_name' ? sorting.ascending === true && sorting.key === '-first_name' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} />
                            </th>
                            <th onClick={() => applySorting('last_name', !sorting.ascending)} >LAST NAME
                              <i className={sorting.key === '-last_name' || sorting.key === 'last_name' ? sorting.ascending === true && sorting.key === '-last_name' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                            </th>
                            <th onClick={() => applySorting('dob', !sorting.ascending)} >DOB
                              <i className={sorting.key === '-dob' || sorting.key === 'dob' ? sorting.ascending === true && sorting.key === '-dob' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                            </th>
                            <th onClick={() => applySorting('country', !sorting.ascending)} >COUNTRY
                              <i className={sorting.key === '-country' || sorting.key === 'country' ? sorting.ascending === true && sorting.key === '-country' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                            </th>
                            <th onClick={() => applySorting('state', !sorting.ascending)} >STATE
                              <i className={sorting.key === '-state' || sorting.key === 'state' ? sorting.ascending === true && sorting.key === '-state' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                            </th>
                            <th onClick={() => applySorting('city', !sorting.ascending)} >CITY
                              <i className={sorting.key === '-city' || sorting.key === 'city' ? sorting.ascending === true && sorting.key === '-city' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                            </th>
                            <th onClick={() => applySorting('pin_code', !sorting.ascending)} >PIN CODE
                              <i className={sorting.key === '-pin_code' || sorting.key === 'pin_code' ? sorting.ascending === true && sorting.key === '-pin_code' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                            </th>
                            <th onClick={() => applySorting('pan_number', !sorting.ascending)} >PAN NUMBER
                              <i className={sorting.key === '-pan_number' || sorting.key === 'pan_number' ? sorting.ascending === true && sorting.key === '-pan_number' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                            </th>
                            <th onClick={() => applySorting('document_number', !sorting.ascending)} >DOCUMENT NUMBER
                              <i className={sorting.key === '-document_number' || sorting.key === 'document_number' ? sorting.ascending === true && sorting.key === '-document_number' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                            </th>
                            <th onClick={() => applySorting('pan_card', !sorting.ascending)} >PAN CARD
                              <i className={sorting.key === '-pan_card' || sorting.key === 'pan_card' ? sorting.ascending === true && sorting.key === '-pan_card' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                            </th>
                            <th onClick={() => applySorting('document_front', !sorting.ascending)} >DOCUMENT FRONT
                              <i className={sorting.key === '-document_front' || sorting.key === 'document_front' ? sorting.ascending === true && sorting.key === '-document_front' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                            </th>
                            <th onClick={() => applySorting('document_back', !sorting.ascending)} >DOCUMENT BACK
                              <i className={sorting.key === '-document_back' || sorting.key === 'document_back' ? sorting.ascending === true && sorting.key === '-document_back' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                            </th>
                            <th onClick={() => applySorting('selfie', !sorting.ascending)} >SELFIE
                              <i className={sorting.key === '-selfie' || sorting.key === 'selfie' ? sorting.ascending === true && sorting.key === '-selfie' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                            </th>
                            <th onClick={() => applySorting('comment', !sorting.ascending)} >COMMENT
                              <i className={sorting.key === '-comment' || sorting.key === 'comment' ? sorting.ascending === true && sorting.key === '-comment' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                            </th>
                            <th onClick={() => applySorting('created_at', !sorting.ascending)} >CREATED AT
                              <i className={sorting.key === '-created_at' || sorting.key === 'created_at' ? sorting.ascending === true && sorting.key === '-created_at' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                            </th>
                            <th onClick={() => applySorting('updated_at', !sorting.ascending)} >UPDATED AT
                              <i className={sorting.key === '-updated_at' || sorting.key === 'updated_at' ? sorting.ascending === true && sorting.key === '-updated_at' ? "fa-sharp fa-solid fa-sort-up mx-2" : "fa-sharp fa-solid fa-sort-down mx-2" : ''} style={{ fontSize: 'small' }} />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading && <Spinner />}

                          {user?.data?.data?.map((value, i) => {
                            return (
                              <tr key={i}>
                                <td>{p++}</td>
                                <td>{value.username}</td>
                                <td>{value.document_type}</td>
                                <td>{value.first_name}</td>
                                <td>{value.last_name}</td>
                                <td>{dateFormat(value.dob, "dd-mm-yyyy")}</td>
                                <td>{value.country}</td>
                                <td>{value.state}</td>
                                <td>{value.city}</td>
                                <td>{value.pin_code}</td>
                                <td>{value.pan_number}</td>
                                <td>{value.document_number}</td>
                                <td>
                                  <div style={{ width: "90px" }}>
                                    <ModalImage
                                      small={`${IMAGE_URL}kyc/${value.pan_image}`}
                                      medium={`${IMAGE_URL}kyc/${value.pan_image}`}
                                      hideDownload={true}
                                      showRotate={true}
                                      hideZoom={true}
                                      className={"rounded"}
                                      alt={`Username: ${value.username}, PAN CARD IMAGE`}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div style={{ width: "90px" }}>
                                    <ModalImage
                                      small={`${IMAGE_URL}kyc/${value.document_front_image}`}
                                      medium={`${IMAGE_URL}kyc/${value.document_front_image}`}
                                      hideDownload={true}
                                      showRotate={true}
                                      hideZoom={true}
                                      alt={`Username: ${value.username}, DOCUMENT FRONT IMAGE`}
                                      className={"rounded"}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div style={{ width: "90px" }}>
                                    <ModalImage
                                      small={`${IMAGE_URL}kyc/${value.document_back_image}`}
                                      medium={`${IMAGE_URL}kyc/${value.document_back_image}`}
                                      hideDownload={true}
                                      showRotate={true}
                                      hideZoom={true}
                                      alt={`Username: ${value.username}, DOCUMENT BACK IMAGE`}
                                      className={"rounded"}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div style={{ width: "90px" }}>
                                    <ModalImage
                                      medium={`${IMAGE_URL}kyc/${value.selfie_image}`}
                                      small={`${IMAGE_URL}kyc/${value.selfie_image}`}
                                      hideDownload={true}
                                      showRotate={true}
                                      hideZoom={true}
                                      className={"rounded"}
                                      alt={`Username: ${value.username}, SELFIE IMAGE`}
                                    />
                                  </div>
                                </td>
                                <td>{value.comment}</td>
                                <td>{dateFormat(value.created_at, "UTC:dd-mm-yyyy TT hh:mm:ss")}</td>
                                <td>{dateFormat(value.updated_at, "UTC:dd-mm-yyyy TT hh:mm:ss")}</td>
                              </tr>
                            );
                          })}
                        </tbody>

                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {records > perPage ?
                <div className="row mt-4">
                  <div className="col-12 text-right">
                    <div className="dataTables_info" id="example1_info" role="status" aria-live="polite" >
                      Showing {currentPage > 1 ? currentPage * perPage - perPage + 1 : currentPage} to {currentPage * perPage} of {records} entries
                    </div>
                  </div>

                  <div className="col-12">
                    <ReactPaginate
                      pageCount={user?.total > 0 ? Math.ceil(user?.total / perPage) : 1}
                      onPageChange={handlePageChange}
                      pageRangeDisplayed={1}
                      marginPagesDisplayed={2}
                      previousLabel={"Previous"}
                      nextLabel={"NEXT"}
                      containerClassName={"pagination justify-content-center"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                      renderOnZeroPageCount={1}
                    />
                  </div>
                </div>
                : null}
            </div>

          </div>
        </div>
      </div>

    </div>
  )
}


export default Rejectedkyc
